import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { GRID_ITEMS, LAYOUT } from "../pages/Analytics/config/data";
import { toast } from "react-hot-toast";
import { GET_ANALYTICS } from "../pages/Analytics/graphql/anaylytics.query";
import { getErrorMessage } from "../utils/error.message";
import moment from "moment";

const SERVICE_NAME = "analytics.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};
export class AnalyticsService {
    name = SERVICE_NAME;
    private static instance: AnalyticsService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new AnalyticsService();
        }
        return this.instance;
    }

    _getSapFailedCalls = (data: any[]) => {
        let _data: any[] = [];
        let _failedData =
            data && Array.isArray(data) && data.length > 0
                ? data.find((item) => item?.status === "error")?.data
                : [];
        console.log(`layout _failedData :`, _failedData);
        _failedData &&
            Array.isArray(_failedData) &&
            _failedData.length > 0 &&
            _failedData.map((item: any, index: number) => {
                _data.push({
                    x: item?.date
                        ? moment(item?.date).format("DD-MM-yyyy")
                        : "",
                    y: item?.count ? item?.count : "",
                });
            });
        let _series = [
            {
                name: "Error API calls",
                data: _data,
            },
        ];

        return _series;
    };

    _getSapCompleteCalls = (data: any[]) => {
        let _data: any[] = [];
        let _completedData =
            data && Array.isArray(data) && data.length > 0
                ? data.find((item) => item?.status === "complete")?.data
                : [];
        console.log(`layout _completedData :`, _completedData);
        _completedData &&
            Array.isArray(_completedData) &&
            _completedData.length > 0 &&
            _completedData.map((item: any, index: number) => {
                _data.push({
                    x: item?.date
                        ? moment(item?.date).format("DD-MM-yyyy")
                        : "",
                    y: item?.count ? item?.count : "",
                });
            });
        let _series = [
            {
                name: "Completed API calls",
                data: _data,
            },
        ];

        return _series;
    };

    _getGraphData = (data: any) => {
        let _data: any[] = [];
        data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.map((item: any, index: number) => {
                _data.push({
                    x: item?.plantName ?? "",
                    y: item?.count,
                });
            });

        return _data;
    };

    _getAnalytics = (data: any) => {
        if (!!data) {
            let _lessPlants: any[] = [];
            let _morePlants: any[] = [];
            let _data =
                GRID_ITEMS &&
                Array.isArray(GRID_ITEMS) &&
                GRID_ITEMS.map((item, index) => {
                    //context?.payload && contex?.payload?.choropleth
                    if (item?.key === "n") {
                        return {
                            ...item,
                            typeProps: {
                                ...item?.typeProps,
                                data:
                                    data &&
                                    data?.choropleth &&
                                    data?.choropleth?.data,
                            },
                        };
                    } else if (item?.key === "k") {
                        return {
                            ...item,
                            typeProps: {
                                ...item?.typeProps,
                                contentProps: {
                                    ...item?.typeProps?.contentProps,
                                    subtitle: {
                                        title: data && data?.counts?.scanlogs,
                                        titleStyle: {
                                            color: "#fff",
                                        },
                                    },
                                },
                            },
                        };
                    } else if (item?.key === "l") {
                        return {
                            ...item,
                            typeProps: {
                                ...item?.typeProps,
                                contentProps: {
                                    ...item?.typeProps?.contentProps,
                                    subtitle: {
                                        title: data && data?.counts?.products,
                                        titleStyle: {
                                            color: "#fff",
                                        },
                                    },
                                },
                            },
                        };
                    } else if (item?.key === "m") {
                        return {
                            ...item,
                            typeProps: {
                                ...item?.typeProps,
                                contentProps: {
                                    ...item?.typeProps?.contentProps,
                                    subtitle: {
                                        title: data && data?.counts?.feedbacks,
                                        titleStyle: {
                                            color: "#fff",
                                        },
                                    },
                                },
                            },
                        };
                    } else if (item?.key === "x") {
                        return {
                            ...item,
                            typeProps: {
                                ...item?.typeProps,
                                contentProps: {
                                    ...item?.typeProps?.contentProps,
                                    subtitle: {
                                        title: data && data?.counts?.gtins,
                                        titleStyle: {
                                            color: "#fff",
                                        },
                                    },
                                },
                            },
                        };
                    } else if (item?.key === "o") {
                        return {
                            ...item,
                            typeProps: {
                                ...item?.typeProps,
                                contentProps: {
                                    ...item?.typeProps?.contentProps,
                                    subtitle: {
                                        title:
                                            data && data?.counts?.provenances,
                                        titleStyle: {
                                            color: "#fff",
                                        },
                                    },
                                },
                            },
                        };
                    } else if (item?.key === "y") {
                        return {
                            ...item,
                            typeProps: {
                                ...item?.typeProps,
                                contentProps: {
                                    ...item?.typeProps?.contentProps,
                                    subtitle: {
                                        title: data && data?.counts?.users,
                                        titleStyle: {
                                            color: "#fff",
                                        },
                                    },
                                },
                            },
                        };
                    } else if (item?.key === "a") {
                        return {
                            ...item,
                            typeProps: {
                                ...item?.typeProps,
                                series: [
                                    {
                                        name: "Scans",
                                        data: data?.scansGraph?.data
                                            ? data?.scansGraph?.data
                                            : [],
                                    },
                                ],
                            },
                        };
                    } else if (item?.key === "b") {
                        return {
                            ...item,
                            typeProps: {
                                ...item?.typeProps,
                                series: [
                                    {
                                        name: "Brand Protection Scans",
                                        data: data
                                            ?.scansGraphWithBrandProtection
                                            ?.data
                                            ? data
                                                  ?.scansGraphWithBrandProtection
                                                  ?.data
                                            : [],
                                    },
                                ],
                            },
                        };
                    } else if (item?.key === "c") {
                        return {
                            ...item,
                            typeProps: {
                                ...item?.typeProps,
                                series: [
                                    {
                                        name: "Ratings",
                                        data: data?.ratingsGraph?.data
                                            ? data?.ratingsGraph?.data
                                            : [],
                                    },
                                ],
                            },
                        };
                    } else {
                        return {
                            ...item,
                        };
                    }
                });
            // if (data?.provPerProductPerFactory?.data) {
            //     let _provData = data?.provPerProductPerFactory?.data
            //         ? data?.provPerProductPerFactory?.data
            //         : [];
            //     let count = 0;
            //     _provData &&
            //         Array.isArray(_provData) &&
            //         _provData.length > 0 &&
            //         _provData.map((item: any, index: number) => {
            //             if (
            //                 item?.data &&
            //                 Array.isArray(item?.data) &&
            //                 item?.data.length > 0 &&
            //                 item?.data?.length < 6
            //             ) {
            //                 _lessPlants.push(item);
            //             } else if (
            //                 item?.data &&
            //                 Array.isArray(item?.data) &&
            //                 item?.data.length > 5
            //             ) {
            //                 _morePlants.push(item);
            //             }
            //         });
            //     let _resData = _lessPlants.concat(_morePlants);
            //     console.log(`layout grid Items _resData: `, _resData);
            //     _resData &&
            //         Array.isArray(_resData) &&
            //         _resData.length > 0 &&
            //         _resData.map((item: any, index: number) => {
            //             _data.push({
            //                 key: `q${count + 1}`,
            //                 backgroundColor: "transparent",
            //                 type: "column-chart",
            //                 typeProps: {
            //                     options: {
            //                         chart: {
            //                             background: "transparent",
            //                             // foreColor: '#fff',
            //                         },
            //                         // colors: [
            //                         //     theme.color6,
            //                         //     theme.secondary_color,
            //                         //     theme.tertiary_color,
            //                         // ],
            //                         markers: {
            //                             size: 0,
            //                         },
            //                         fill: {
            //                             type: "solid",
            //                             gradient: {
            //                                 shadeIntensity: 1,
            //                                 opacityFrom: 0.7,
            //                                 opacityTo: 0.9,
            //                                 stops: [0, 90, 100],
            //                             },
            //                         },
            //                         plotOptions: {
            //                             bar: {
            //                                 horizontal: false,
            //                                 columnWidth: "55%",
            //                                 endingShape: "rounded",
            //                                 dataLabels: {
            //                                     position: "top",
            //                                 },
            //                             },
            //                         },
            //                         dataLabels: {
            //                             enabled: true,
            //                             style: {
            //                                 colors: ["#000"],
            //                             },
            //                             offsetY: -25,
            //                         },
            //                         stroke: {
            //                             show: true,
            //                             width: 2,
            //                             colors: ["transparent"],
            //                         },
            //                         xaxis: {
            //                             type: "category",
            //                             scrollbar: {
            //                                 enabled: true,
            //                             },
            //                         },
            //                     },
            //                     series: [
            //                         {
            //                             name: item?.name,
            //                             //data: PROVENACE_DATA
            //                             data: this._getGraphData(item?.data),
            //                         },
            //                     ],
            //                     headerProps: {
            //                         title: `Number of Provenances of ${item?.name} Per Factory`,
            //                         titleType: "title3",
            //                         titleStyle: {
            //                             color: "#000",
            //                             fontWeight: "bold",
            //                         },
            //                     },
            //                     cardStyle: {},
            //                     cardHeaderStyle: {
            //                         backgroundColor: "#d2d2d2",
            //                     },
            //                     footerProps: {
            //                         cardFooterStyle: {
            //                             backgroundColor: "transparent",
            //                         },
            //                         title: "",
            //                         titleType: "footnote",
            //                         titleStyle: {
            //                             textAlign: "right",
            //                         },
            //                     },
            //                 },
            //             });
            //             ++count;
            //         });
            // }
            console.log(`layout grid Items : `, _data);
            return _data;
        }
    };

    _getLGlayout = (data: any) => {
        let _layout = LAYOUT;
        let _lessPlants: any[] = [];
        let _morePlants: any[] = [];
        let _data = data?.provPerProductPerFactory?.data
            ? data?.provPerProductPerFactory?.data
            : [];
        let count = 0;
        let x_axis = 0;
        let y_axis = 8;
        console.log(`layout's in service _data: `, _data);
        _data &&
            Array.isArray(_data) &&
            _data.length > 0 &&
            _data.map((item: any, index: number) => {
                if (
                    item?.data &&
                    Array.isArray(item?.data) &&
                    item?.data.length > 0 &&
                    item?.data?.length < 6
                ) {
                    _lessPlants.push(item);
                } else if (
                    item?.data &&
                    Array.isArray(item?.data) &&
                    item?.data.length > 5
                ) {
                    _morePlants.push(item);
                }
            });
        let _resData = _lessPlants.concat(_morePlants);
        console.log(`layout's in service _data in _resData: `, _resData);
        _resData &&
            Array.isArray(_resData) &&
            _resData.length > 0 &&
            _resData.map((item: any, index: number) => {
                if (
                    item?.data &&
                    Array.isArray(item?.data) &&
                    item?.data.length > 0 &&
                    item?.data?.length < 6
                ) {
                    if (count > 1)
                        y_axis = count % 2 === 0 ? y_axis + 1 : y_axis;
                    x_axis = count % 2 === 0 ? 0 : 7;

                    _layout.push({
                        i: `q${count + 1}`,
                        x: x_axis,
                        y: y_axis,
                        w: 6,
                        h: 6,
                    });
                    ++count;
                } else if (
                    item?.data &&
                    Array.isArray(item?.data) &&
                    item?.data.length > 5
                ) {
                    x_axis = 0;
                    y_axis = y_axis + 1;
                    _layout.push({
                        i: `q${count + 1}`,
                        x: x_axis,
                        y: y_axis,
                        w: 12,
                        h: 6,
                    });
                    ++count;
                }
            });

        console.log(`layout's in service: `, _layout);
        return _layout;
    };

    async getAnalytics() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_ANALYTICS,
                    variables: {},
                    fetchPolicy: "network-only",
                });
                return {
                    gridItems: this._getAnalytics(res?.data?.getAnalytics),
                    layout: LAYOUT, // this._getLGlayout(res?.data?.getAnalytics),
                };
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
